import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#language",
        "aria-label": "language permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language`}</h2>
    <p>{`Language consists of five strands: listening, speaking, reading, writing, viewing and presenting. Development in one strand supports growth in the other strands.`}</p>
    <p><strong parentName="p">{`Listening`}</strong>{`: Students learn to listen attentively and demonstrate consideration for the speaker. They learn to understand and evaluate what they hear, to think about both literal and inferred meanings, and to respond appropriately. They learn to listen for specific information, considering the context, the speaker and the audience.`}</p>
    <p><strong parentName="p">{`Speaking:`}</strong>{` Students use oral language to ask and answer questions; collect information, relate and retell; persuade; talk about needs, feelings, ideas, and opinions; and to contribute to discussions in a range of formal and informal situations. Students learn to respond confidently, appropriately and relevantly considering the context, the speaker and the audience. They develop their pronunciation, intonation and fluency. They expand their vocabulary and start using more complex sentences. They experience speaking in front of small and large audiences.`}</p>
    <p><strong parentName="p">{`Reading:`}</strong>{` Students read to gain meaning from text. The students learn to use a variety of strategies to gain meaning, including their understanding of phonics, the use of context clues and picture clues. The process of reading is interactive and involves the reader’s purpose for reading, the reader’s prior knowledge and experience, and the purpose and the structure of the text itself. Students need to learn how to understand, interpret and respond to the ideas, attitudes and feelings expressed in various texts; to think critically about what they read; and to be able to make predictions and inferences based on information that is both explicit and implicit in a text.`}</p>
    <p><strong parentName="p">{`Writing`}</strong>{`: Students write as a means to remember, develop, organise, gain self-knowledge and communicate ideas, feelings and information. Purpose and audience contribute to the form and substance of writing as well as to its style and tone. Students learn to write through a developmental process. They need to focus first on meaning rather than accuracy. Grammar, spelling, handwriting, punctuation and paragraphing are taught gradually through writing practice, which allows students to experience the writing process in an environment where students acquire the skills to achieve written products for a variety of purposes.`}</p>
    <p><strong parentName="p">{`Viewing and Presenting`}</strong>{`: Students learn to present and interpret visuals, broadening the ways in which they can understand and communicate their learning. Acquiring skills related to advanced technology and media gives students the opportunity to communicate, respond and comprehend thoughts, ideas, and feelings. A variety of formats including visual, (e.g., photos, graphs, cartoons), dramatic (e.g., tableaux, improvisations, live theatre), and multimedia (e.g., videos, television, computer) engage viewers, allowing them instant access to data. Learning to understand and use different media expands the sources of information and expressive abilities of students.`}</p>
    <p><strong parentName="p">{`Additional Languages.`}</strong>{` We offer a choice of four additional languages: Ukrainian, Spanish, French and Russian.Ukrainian is the official language of Ukraine, our host country, and as such has a special place in our curriculum. Russian is widely used in Ukraine and is offered as an alternative additional language. Spanish and French are presented as globally used languages for students wishing to pursue a language at a future school. The PYP provides Grade 1-5 students with Ukrainian, Spanish or Russian language classes, unless the student is enrolled in our English as an Additional Language class. Languages are taught to gain an ability to communicate and whenever possible, support the unit of inquiry. `}</p>
    <h2 {...{
      "id": "english-as-an-additional-language-eal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#english-as-an-additional-language-eal",
        "aria-label": "english as an additional language eal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`English as an Additional Language (EAL)`}</h2>
    <p>{`Non-English speaking students are given extra support depending on the needs and abilities of the student. Support can include attending daily EAL classes, in-class support and individual help. `}</p>
    <h4 {...{
      "id": "language-profile-development",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#language-profile-development",
        "aria-label": "language profile development permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language Profile Development`}</h4>
    <p>{`At PSI, teachers support language development with consideration for the language profiles of students and students take opportunities to develop their language profiles.`}</p>
    <p>{`In the PYP, it is recognised that all students should have opportunities to develop their mother tongue. PSI believes that supporting the development of a student's mother tongue and literacy skills in their home language also supports the development of English language skills. Currently, PSI offers lessons during the school day to native speakers of Russian and Ukrainian. Students in Grade 1 have three lessons each week, while in Grades 2 - 5 students have five lessons each week.`}</p>
    <p>{`Additionally, the school provides Mother Tongue support lessons after school as part of our Extra-Curricular Activities (ECA) programme.The Mother Tongue lessons that are offered are dependent on family interest and teacher availability. Currently we offer Spanish, French, Dutch, Armenian and Russian lessons for native / nearly native speakers. The school also supports the Ukrainian Externum programme for those wishing to receive a Ukrainian diploma as well as one from PSI. These classes are held after school and on Saturdays.`}</p>
    <p>{`The school’s Extracurricular Activities Coordinator liaises with families to support the finding of teachers and providing space for the after school lessons.`}</p>
    <h2 {...{
      "id": "literacy-reading-and-writing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#literacy-reading-and-writing",
        "aria-label": "literacy reading and writing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Literacy (Reading and Writing)`}</h2>
    <p>{`A balanced approach to literacy encompasses many layers. First, ‘the learning process simultaneously involves learning language—as learners listen to and use language with others in their everyday lives; learning about language—as learners grow in their understanding of how language works; and learning through language—as learners use language as a tool to listen, think, discuss and reflect on information, ideas and issues (Halliday 1980). An appreciation of these aspects of language learning may help teachers better understand and enhance students’ learning. However, these three aspects are so inextricably linked they are best not thought of as discrete processes’. (IBO PYP `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1LroAVWs1H-YTAh3uz81qx3b43EXh5z7R/view?usp=sharing"
      }}><u>{`Language Scope and`}</u>{` `}<em parentName="a"><u>{`Sequence, 2018`}</u></em></a>{`).`}</p>
    <p>{`Secondly, approaches in the classroom need to be balanced so that exposure and experiences mimic real life and provide appropriate scaffolding as students learn and grow. There should be many experiences through the school year with the following strategies:`}</p>
    <h4 {...{
      "id": "reading-and-writing-workshop",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reading-and-writing-workshop",
        "aria-label": "reading and writing workshop permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reading and Writing Workshop:`}</h4>
    <p>{`Reading and Writing Workshop are structures / programmes of teaching reading and writing developed by Lucy Calkins at the Teachers College of Columbia University. Reading and Writing Workshop are research based programmes which ‘aim to prepare learners for any reading and writing task they will face or set themselves, to turn them into life-long, confident readers and writers who display agency and independence in their future endeavors.’ (`}<a parentName="p" {...{
        "href": "http://readingandwritingproject.org/about/research-base"
      }}><u>{`http://readingandwritingproject.org/about/research-base`}</u></a>{`)`}</p>
    <p>{`While the majority of our students are non-native English speakers (and English is the language of inclusion at PSI), we believe that using the same programme throughout the primary school benefits students as we engage in the use of the same language from year to year, teach using agreed upon strategies found in Reading and Writing Workshop, and continue to build stamina within our students.`}</p>
    <p>{`As part of a balanced literacy programme the following components are expected to be incorporated into our daily instruction of reading and writing as the teacher sees fit:`}</p>
    <p><strong parentName="p">{`Read Aloud`}</strong>{` ‐ When the teacher selects a text to read aloud to the students. This text is usually something that the students would not be able to read on their own.`}</p>
    <p><strong parentName="p">{`Shared Reading`}</strong>{` ‐ The teacher selects a text that is slightly above the range of the class. The teacher is working on (depending on the grade level) one to one correspondence, letters / sounds, sight words, fluency, author's craft, etc with the class.`}</p>
    <p><strong parentName="p">{`Guided Reading`}</strong>{` ‐ Guided reading is a strategy that helps students become good readers. The teacher provides support for small groups of readers as they learn to use various reading strategies (context clues, letter and sound relationships, word structure, and so forth). Although guided reading has been traditionally associated with primary grades it can be modified and used successfully in all grade levels. For example, older students may need to learn new strategies to understand how to read an information book in a way that is going to give them access to the information they are seeking.`}</p>
    <p><strong parentName="p">{`Conferring`}</strong>{` - The teacher uses anecdotal notes to prepare for one on one conferences with readers and writers. Conferences are structured around the teacher providing compliments, researching teaching points and strategies and setting goals for the reader and writer to achieve.`}</p>
    <p><strong parentName="p">{`Modelled Writing`}</strong>{` – when the teacher is thinking aloud and writing so all the students can hear.`}</p>
    <p><strong parentName="p">{`Shared Writing`}</strong>{` – A teaching process where the teacher and students (large or small group) collaborate to write a text together on chart paper, whiteboard, or any other large surface that all the students can see. The teacher has most of the responsibility, working as the scribe, though using a ‘shared pen’ technique students can be involved in the writing process.`}</p>
    <p><strong parentName="p">{`Guided Writing`}</strong>{` ‐ the teacher is working with a small group of students based on a common need (ex: small group for punctuation, use of similes, creating interesting leads).`}</p>
    <p>{`Our Diagnostic Reading Assessment (DRA2) allows us to determine the level of independent and instructional reading of each student, which in turn allows teachers and students to choose books and texts that are at just the right level of learning for the student. These assessments are administered to each child in a 1:1 capacity and by the homeroom teacher and given in the fall and spring of each school year. Teachers are able to determine the progress of a student’s reading as well as their areas of strength and what strategies the student needs to improve upon.`}</p>
    <h2 {...{
      "id": "mathematics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mathematics",
        "aria-label": "mathematics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mathematics`}</h2>
    <p>{`The mathematics programme at PSI inspires students to discover the beauty and power of mathematics. In both Primary and Secondary School, students work both independently and cooperatively to become flexible, fluent and accurate in using multiple strategies and tools for solving mathematical problems and recognising patterns. At every grade, students develop their abilities to clearly communicate their thinking orally, in writing and using models, as they efficiently apply their mathematical understandings to find solutions to real-life problems. Learners are supported by teachers who use a differentiated approach to deliver inquiry-based, concept-driven instruction.`}</p>
    <p>{`Our Mathematics programme aims to develop the students’ understanding of mathematical operations and concepts, as well as apply and communicate this understanding in real contexts. We expect students to develop an efficient and accurate use of mathematical skills such as computation and measurement, as well as develop an appreciation of mathematics and the confidence to approach new mathematical concepts and contexts with confidence and interest. We aim to provide opportunities for students to apply their knowledge to unique problems.`}</p>
    <p>{`The teaching methods we use to develop these understandings, skills and attitudes, include the investigations of realistic contexts through discussion, hands-on activities, mathematical operations and a wide variety of mathematical models and manipulatives to represent mathematical concepts and practices.`}</p>
    <p>{`At each level, students gain essential understanding and see relationships in the main themes of the mathematics curriculum: shape and space, number, measurement, handling data and patterns, and functions.`}</p>
    <p>{`Beginning in the 2021 - 2022 school year, the PSI Primary School will administer the JAM and GLoSS maths assessment for Kindergarten through Grade 5 students in the fall and spring of each school year. These assessments are administered in a 1:1 capacity by the homeroom teachers and assists us in defining the stage of mathematics development the student is in. They also allow us to determine the areas of strength and areas of misconception a student may have as well as progress throughout the year.`}</p>
    <h4 {...{
      "id": "the-strands-of-the-maths-curriculum",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#the-strands-of-the-maths-curriculum",
        "aria-label": "the strands of the maths curriculum permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Strands of the Maths Curriculum`}</h4>
    <p><strong parentName="p">{`Data handling:`}</strong>{` Data handling allows us to make a summary of what we know about the world and to make inferences about what we do not know. Data can be collected, organised, represented and summarised in a variety of ways to highlight similarities, differences and trends; the chosen format should illustrate the information without bias or distortion. Probability can be expressed qualitatively by using terms such as “unlikely”, “certain” or “impossible”. It can be expressed quantitatively on a numerical scale.`}</p>
    <p><strong parentName="p">{`Measurement:`}</strong>{` To measure is to attach a number to a quantity using a chosen unit. Since the attributes being measured are continuous, ways must be found to deal with quantities that fall between numbers. It is important to know how accurate a measurement needs to be or can ever be.`}</p>
    <p><strong parentName="p">{`Shape and space:`}</strong>{` The regions, paths and boundaries of natural space can be described by shape. An understanding of the interrelationships of shape allows us to interpret, understand and appreciate our two-dimensional (2D) and three-dimensional (3D) world.`}</p>
    <p><strong parentName="p">{`Pattern and function:`}</strong>{` To identify pattern, is to begin to understand how mathematics applies to the world in which we live. The repetitive features of patterns can be identified and described as generalised rules called “functions”. This builds a foundation for the later study of algebra.`}</p>
    <p><strong parentName="p">{`Number:`}</strong>{` Our number system is a language for describing quantities and the relationships between quantities. For example, the value attributed to a digit depends on its place within a base system. Numbers are used to interpret information, make decisions and solve problems. For example, the operations of addition, subtraction, multiplication and division are related to one another and are used to process information in order to solve problems. The degree of precision needed in calculating depends on how the result will be used.`}</p>
    <p>{`The availability of computers and calculators provides students with the opportunity to process data and explore probability in more thoughtful, efficient, and imaginative ways. The educational experiences of students must include the use of technology.`}</p>
    <h2 {...{
      "id": "social-studies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#social-studies",
        "aria-label": "social studies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Social Studies`}</h2>
    <p>{`Social Studies is viewed as the study of people in relation to their past, their present and their future, their environment and their society. Social Studies encourages curiosity and develops an understanding of a rapidly changing world. Through Social Studies, students develop an understanding of their personal and cultural identities. They develop the skills and knowledge needed to participate actively in their classroom, their school, their community and the world: to understand themselves in relation to their communities. The aim of Social Studies within the PYP is to promote intercultural understanding and respect for individuals and their values and traditions.`}</p>
    <p>{`We cover these five Social Studies strands through the Units of Inquiry:`}</p>
    <p><strong parentName="p">{`Human systems and economic activities`}</strong>{`: The study of how and why people construct organisations and systems; the ways in which people connect locally and globally; the distribution of power and authority.`}</p>
    <p><strong parentName="p">{`Social organisation and culture`}</strong>{`: The study of people, communities, cultures and societies; the ways in which individuals, groups and societies interact with each other.`}</p>
    <p><strong parentName="p">{`Continuity and change through time`}</strong>{`: The study of the relationships between people and events through time; the past, its influences on the present and its implications for the future; people who have shaped the future through their actions.`}</p>
    <p><strong parentName="p">{`Human and natural environments`}</strong>{`: The study of the distinctive features that give a place its identity; how people adapt to and alter their environment; how people experience and represent place; the impact of natural disasters on people and the built environment.`}</p>
    <p><strong parentName="p">{`Resources and the environment`}</strong>{`: The interaction between people and the environment; the study of how humans allocate and manage resources; the positive and negative effects of this management; the impact of scientific and technological developments on the environment.`}</p>
    <h2 {...{
      "id": "science",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#science",
        "aria-label": "science permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Science`}</h2>
    <p>{`Learning in science is fundamental to understanding the world in which we live and work. In the PYP, science is viewed as the exploration of the behaviours of, and the interrelationships among the natural, physical and material worlds. Our understanding of science is constantly changing and evolving. The inclusion of science within the curriculum leads learners to an appreciation and awareness of the world as it is viewed from a scientific perspective. It encourages curiosity, develops an understanding of the world, and enables the individual to develop a sense of responsibility regarding the impact of their actions on themselves, others and their world. `}</p>
    <p>{`We cover these four science strands through the Units of Inquiry:`}</p>
    <p><strong parentName="p">{`*Living things`}</strong>{`:* The study of the characteristics, systems and behaviours of humans and other animals, and of plants; the interactions and relationships between and among them, and with their environment.`}</p>
    <p><strong parentName="p">{`*Earth and space`}</strong>{`:* The study of planet Earth and its position in the universe, particularly its relationship with the sun; the systems; distinctive features and natural phenomena that shape and identify the planet; the infinite and finite resources of the planet.`}</p>
    <p><strong parentName="p">{`*Materials and matter`}</strong>{`:* The study of the properties, behaviours and uses of materials, both natural and human-made; the origins of human-made materials and how they are manipulated to suit a purpose.`}</p>
    <p><strong parentName="p">{`*Forces and energy`}</strong>{`:* The study of energy, its origins, storage and transfer, and the work it can do; the study of forces; the application of scientific understanding through inventions and machines.`}</p>
    <h2 {...{
      "id": "personal-social-and-physical-education",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#personal-social-and-physical-education",
        "aria-label": "personal social and physical education permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Personal, Social and Physical Education`}</h2>
    <p>{`In the PYP, personal, social and physical education (PSPE) is concerned with the individual’s well-being through the promotion and development of concepts, knowledge, attitudes and skills. Well-being is intrinsically linked to all aspects of a student’s experience at school and beyond. It encompasses physical, emotional, cognitive, spiritual and social health and development. It contributes to an understanding of self, to developing relationships with others, and to participation in an active, healthy lifestyle. Student learning in PSPE is defined through three common strands: `}<em parentName="p">{`Identity, Active Living,`}</em>{` and `}<em parentName="p">{`Interactions.`}</em>{` These strands are concept-driven and have been designed to interact with each other, working together to support the overall development of students.`}</p>
    <p>{`Physical Education is an integral element of each student's education and aims to enhance each individual’s well-being. Through the physical education programme, students learn how to demonstrate basic skills and concepts, and then successfully apply them in more complex and realistic contexts. In addition to providing authentic learning experiences through the transdisciplinary programme of inquiry, PSI provides an ongoing, balanced physical education programme. These learning experiences include `}<em parentName="p">{`Games`}</em>{` (e.g., soccer, basketball, badminton, evasive games), `}<em parentName="p">{`Individual Pursuits`}</em>{` (e.g., swimming, athletics), `}<em parentName="p">{`Movement Composition`}</em>{` (e.g., gymnastics, movement to music), `}<em parentName="p">{`Adventure Challenges`}</em>{` and `}<em parentName="p">{`Health-Related Fitness`}</em>{`.  `}</p>
    <h2 {...{
      "id": "the-arts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-arts",
        "aria-label": "the arts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Arts`}</h2>
    <p>{`The content in the Arts (Music and Visual art) is presented in the context of two distinct strands, `}<strong parentName="p"><em parentName="strong">{`responding`}</em></strong>{` and `}<strong parentName="p"><em parentName="strong">{`creating`}</em></strong>{`.`}</p>
    <h4 {...{
      "id": "responding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#responding",
        "aria-label": "responding permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Responding`}</h4>
    <p>{`The process of responding provides students with opportunities to respond to their own and other artists’ works and processes, and in doing so develop the skills of critical analysis, interpretation, evaluation, reflection and communication. Students will demonstrate knowledge and understanding of the concepts, methods and elements of drama, dance, music and visual arts, including using specialised language.`}</p>
    <p>{`Students consider their own and other artists’ works in context and from different perspectives in order to construct meaning and inform their own future works and processes. The responding strand is not simply about reflecting; responding may include creative acts, and encompasses presenting, sharing and communicating one’s own understanding. By responding to their own artwork and that of others, students become more mindful of their own artistic development and the role that arts play in the world around them.`}</p>
    <h4 {...{
      "id": "creating",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#creating",
        "aria-label": "creating permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating`}</h4>
    <p>{`The process of creating provides students with opportunities to communicate distinctive forms of meaning, develop their technical skills, take creative risks, solve problems and visualise consequences. Students are encouraged to draw on their imagination, experiences and knowledge of materials and processes as starting points for creative exploration. They can make connections between their work and that of other artists to inform their thinking and to provide inspiration. Both independently and collaboratively, students participate in creative processes through which they can communicate ideas and express feelings. The creating strand provides opportunities for students to explore their personal interests, beliefs and values and to engage in a personal artistic journey.`}</p>
    <p>{`The responding and creating strands are dynamically linked in an ongoing and reflexive relationship. Students are encouraged to reflect continually upon their work throughout the process of creating, thus reinforcing the close link between these strands.`}</p>
    <h2 {...{
      "id": "music",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#music",
        "aria-label": "music permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Music`}</h2>
    <p>{`Whilst there are five distinct strands to learning in Music, all five can be an integrated and integral part of every Unit of Inquiry.  Where appropriate, Music units support the homeroom Unit of Inquiry and at every grade level one unit per year has the Arts as its central focus. The Music strands are:`}</p>
    <p><strong parentName="p">{`Performing: Singing -`}</strong>{` The students learn to sing songs from a variety of times and cultures. They learn to sing songs with increasing accuracy, control, confidence and expression in a wide pitch range, being aware of dynamics and tempo, and develop a sense of audience.`}</p>
    <p><strong parentName="p">{`Performing: Playing Instruments -`}</strong>{` The students work towards playing instruments with increasing accuracy in solo and ensemble performances. They play rhythmic and melodic patterns and learn to express tempo, dynamics, texture, style and articulation, responding to directions from a conductor. They initially use non-traditional notation and move on to traditional notation in upper primary.`}</p>
    <p><strong parentName="p">{`Creating and Composing -`}</strong>{` Students learn to create music in response to a range of stimuli. They organise sounds in simple musical forms and work towards using motifs and sequence. They learn to use notation to record their own compositions.`}</p>
    <p><strong parentName="p">{`Notation -`}</strong>{` Students use traditional and non-traditional notation to represent and record sound events and songs and to practice and perform a piece of music.`}</p>
    <p><strong parentName="p">{`Listening and Appreciation -`}</strong>{` Students learn to describe musical elements such as rhythmic patterns and melodic patterns using appropriate vocabulary. Students learn to distinguish a range of instrumental sounds, including orchestral, non-orchestral, non-western and multi-ethnic. Students develop an awareness and appreciation of music from different sources and cultures; its uses and associations.`}</p>
    <h2 {...{
      "id": "visual-art",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#visual-art",
        "aria-label": "visual art permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Visual Art`}</h2>
    <p>{`At PSI, we believe the Visual Arts are a source of creativity, inspiration and a strong means to harmonically develop the whole child. The Visual Arts are used to support the development of intellectual and emotional abilities, as well as fine motor skills, making choices and an appreciation of the world, cultures, traditions and religions.`}</p>
    <p>{`Through hands-on practical activities, students gain technical and aesthetic skills and learn what it means to be able to express themselves through art. As much as possible, Visual Art is integrated into the units of inquiry or supports the unit.`}</p>
    <h3 {...{
      "id": "primary-students-receive-instruction-outside-the-homeroom-class-as-follows-per-6-day-rotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#primary-students-receive-instruction-outside-the-homeroom-class-as-follows-per-6-day-rotation",
        "aria-label": "primary students receive instruction outside the homeroom class as follows per 6 day rotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Primary students receive instruction outside the homeroom class as follows (per 6 day rotation):`}</h3>
    <p><strong parentName="p">{`Early Childhood`}</strong></p>
    <p>{`Physical Education  for 3 and 4 year olds: 4 x 40 minute periods per 6 day rotation`}</p>
    <p>{`Music 1 x 30 minutes per week per six day rotation`}</p>
    <p>{`Art 1 x 30 minutes per week per six day rotation`}</p>
    <p>{`Host Country Culture 1 x 30 minutes per six day rotation`}</p>
    <p><strong parentName="p">{`Kindergarten`}</strong></p>
    <p>{`Physical Education 2 x 40 and 1 x 80 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Music 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Art 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`English as an Additional Language (EAL) as a push-in model 3 x 40 minutes per six day rotation`}<br parentName="p"></br>{`
`}{`Host Country Culture 1 x 40 minutes per six day rotation`}</p>
    <p><strong parentName="p">{`Grade 1`}</strong></p>
    <p>{`Physical Education 2 x 40 minute and 1 x 80 minute per six day rotation`}</p>
    <p>{`Music 2 x 40 minute periods a week per six day rotation`}<br parentName="p"></br>{`
`}{`Art 1 x 80 minute periods a week per six day rotation`}<br parentName="p"></br>{`
`}{`Additional Language (Ukrainian, Russian, Spanish, EAL) 3 x 30 minute per six day rotation`}</p>
    <p>{`Host Country Culture 1 x 30 minutes per six day rotation`}</p>
    <p><strong parentName="p">{`Grade 2 through 5`}</strong></p>
    <p>{`Physical Education 1 x 40 minute and 1 x 80 minute per six day rotation`}<br parentName="p"></br>{`
`}{`Music 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Art 1 x 80 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Additional Language (Ukrainian, Russian, Spanish, EAL) 6 x 40 minutes per six day rotation`}</p>
    <p>{`Library and IT skills are provided to students through integrated projects and lessons. The school teacher librarian and the IT Integration Specialist work in collaboration with grade level teams throughout the year. Children are allowed to check out books when needed or during scheduled classes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      